class AiForm extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
    this.setupEventListeners();
    // Autofocus when loaded
    this.focus();
  }

  render() {
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: block;
        }
        form {
          display: flex;
          align-items: center;
          background: rgba(0, 100, 0, 0.3);
          padding: 1em;
          border-radius: 0.33rem;
          color: rgba(0, 255, 0, 0.66);
        }
        #input {
          flex-grow: 1;
          background-color: transparent;
          outline: none;
          border: none;
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          min-width: 1ch;
        }
        .message {
          opacity: 0.5;
        }
      </style>
      <form>
        <input name="input" type="text" id="input" autocomplete="off" />
      </form>
    `;
  }

  setupEventListeners() {
    const form = this.shadowRoot.querySelector("form");
    const input = this.shadowRoot.getElementById("input");

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      const value = input.value;
      this.dispatchEvent(new CustomEvent("ai-form-submit", { detail: value }));
    });

    input.addEventListener("input", () => {
      input.style.width = `${input.value.length + 1}ch`;
    });
  }

  setPlaceholder(text) {
    const input = this.shadowRoot.getElementById("input");
    input.setAttribute("placeholder", text);
  }

  focus() {
    const input = this.shadowRoot.getElementById("input");
    input.focus();
  }

  reset() {
    const input = this.shadowRoot.getElementById("input");
    input.value = "";
    input.classList.remove("message");
    input.removeAttribute("disabled");
    this.focus();
  }
}

customElements.define("ai-form", AiForm);
