require("./components/terminal/ai-terminal.js");
require("./components/form/ai-form.js");
require("./components/nav/ai-nav.js");
require("./components/assistants/ai-assistants.js");

const content = {
  "/": `
  <p>I'm your AI Assistant for any web site</p>
  <p>What web site do you want to interact with?</p>
  <ai-form id="input-line"></ai-form>
  <p>Or try one of these:</p>
  <ai-assistants></ai-assistants>
  `,
  "/assistants": `
  <p>How can I help you today?</p>
  <ai-assistants></ai-assistants>
  <p>Or try any other web site you want:</p>
  <ai-form id="input-line"></ai-form>
  `,
  "/assistants/shopify": `
  <p>Hey, I'm your shopify.com assistant.</p>
  <p>How can I help you today?</p>
  <p><a href="/assistants/shopify/build-store">Build a store</a>
  </p>
  `,
  "/assistants/youtube": `
  <p>Hey, I'm your youtube.com assistant.</p>
  <p>How can I help you today?</p>
  <p><a href="/assistants/youtube/transcript">Get video transcription</a>
  </p>
  `,
  "/assistants/shopify/build-store": `
  <p>To build your store, first I'll create a Shopify account for you.</p>
  <p>What is your e-mail?</p>
  <ai-form></ai-form>
  `,
};

const behavior_id = "1234567890";

document.addEventListener("DOMContentLoaded", () => {
  const terminal = document.querySelector("ai-terminal");

  const notFound = `<p>...</p>
  <p>Sorry, I'm too busy right now, your request is in the queue.</p>
  <p>For your convenience, I'll send you a WhatsApp message when it's ready.</p>
  <p><a href="https://wa.me/5531996975408?text=${encodeURI(
    `KEY: ${behavior_id}\n\nHey Guima, this is the key of my request, please informe me when it's ready.`
  )}" target="_blank">Click to be informed</a></p>`;

  const { pathname } = window.location;
  const initialMessage = content[pathname] || notFound;

  terminal.typeMessage(initialMessage);

  // Override the processCommand method
  terminal.processCommand = (command) => {
    const answer = {
      "/": `<p>I'm learning about ${command}</p>
      <p>You can chat with it in your WhatsApp.</p>
      <p><a href="https://wa.me/5531996975408?text=${encodeURI(
        `KEY: ${behavior_id}\n\nHey Guima, this is the key to access my AI Assistant.`
      )}" target="_blank">Click to chat</a></p>
      `,
      "/assistants/shopify/build-store": `<p>I'm creating your store...</p>
      <p>For your safety, I'll send your temporary password to your WhatsApp.</p>
      <p><a href="https://wa.me/5531996975408?text=${encodeURI(
        `KEY: ${behavior_id}\n\nHey Guima, this is my key to get my Shopify store password.`
      )}" target="_blank">Get your temporary password</a></p>
      `,
    };

    const message = answer[pathname] || "I'm sorry, I don't understand.";
    // Here you can add logic to process user commands
    terminal.appendOutput(message);
  };
});
