class AiNav extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
  }

  render() {
    const { origin, host, pathname } = window.location;
    const pathSegments = pathname
      .split("/")
      .filter((segment) => segment !== "");

    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: flex;
          align-items: center;
          padding: 10px;
          color: rgba(0, 255, 0, 0.2) !important;
        }
        a {
          text-decoration: none;
          color: rgba(0, 255, 0, 0.3) !important;
        }
        a:hover {
          text-decoration: underline;
        }
      </style>
      <nav>
        <a href="${origin}">${host}</a>
        ${pathSegments
          .map(
            (segment, index) => `
          <span>/</span>
          <a href="${origin}/${pathSegments
              .slice(0, index + 1)
              .join("/")}">${segment}</a>
        `
          )
          .join("")}
      </nav>
    `;
  }
}

customElements.define("ai-nav", AiNav);

module.exports = AiNav;
