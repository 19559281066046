require("../form/ai-form.js");

class AiTerminal extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
    this.setupMutationObserver();
  }

  render() {
    this.shadowRoot.innerHTML = `
            <style>
                a {
                    border: 1px solid rgba(0, 100, 0, 0.6);
                    border-bottom-width: 4px;
                    background-color: rgba(0, 100, 0, 0.6);
                    color: rgba(0, 255, 0, 0.8);
                    display: inline-block;
                    padding: 0.8em 1em 0.7em 1em;
                    text-decoration: none;
                    line-height: 1;
                    border-radius: 0.33rem;
                    cursor: pointer;
                    transition: background-color 0.3s, color 0.3s;
                }
                a:hover {
                    background-color: rgba(0, 100, 0, 0.8);
                }
                :host {
                    display: block;
                    flex-direction: column;
                    font-family: monospace;
                    font-size: large;
                    line-height: 1.3;
                    font-weight: 400;
                    max-width: 100%;
                    word-wrap: break-word;
                }
                #terminal {
                    max-width: 42rem;
                    margin: 0 auto 0;
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
                #output {
                    white-space: pre-wrap;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    line-height: 1.5;
                }
                #output p {
                    margin: 0;
                }
                ai-form {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }
            </style>
            <div id="terminal">
                <div id="output"></div>
            </div>
        `;
  }

  setupMutationObserver() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          const addedNodes = mutation.addedNodes;
          for (let i = 0; i < addedNodes.length; i++) {
            if (addedNodes[i].nodeName.toLowerCase() === "ai-form") {
              this.setupEventListeners(addedNodes[i]);
              observer.disconnect(); // Stop observing once we've found the ai-form
              return;
            }
          }
        }
      });
    });

    observer.observe(this.shadowRoot, { childList: true, subtree: true });
  }

  setupEventListeners(form) {
    if (!form) return;
    form.addEventListener("ai-form-submit", (e) => {
      const command = e.detail;
      this.processCommand(command);
    });
  }

  appendOutput(text) {
    this._typeText(text);
  }

  async _typeText(html, delay = 2, pause = 100) {
    const output = this.shadowRoot.getElementById("output");

    // Parse the HTML string into a DOM tree
    const temp = document.createElement("div");
    temp.innerHTML = html;

    // Recursive function to type node and append to output
    const typeNode = async (node, parent) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent;
        const typedNode = document.createTextNode("");
        parent.appendChild(typedNode);

        for (let i = 0; i < text.length; i++) {
          typedNode.textContent += text.charAt(i);
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const elementNode = document.createElement(node.tagName.toLowerCase());

        // Copy attributes to new element
        for (let attr of node.attributes) {
          elementNode.setAttribute(attr.name, attr.value);
        }

        parent.appendChild(elementNode);

        for (let child of node.childNodes) {
          await typeNode(child, elementNode);
        }
      }

      // Pause after typing node
      await new Promise((resolve) => setTimeout(resolve, pause));
    };

    for (let node of temp.childNodes) {
      await typeNode(node, output);
    }

    // Scroll to bottom after typing completes
    const terminal = this.shadowRoot.getElementById("terminal");
    terminal.scrollTop = terminal.scrollHeight;
  }

  typeMessage(message) {
    this._typeText(message);
  }

  processCommand(command) {
    this.appendOutput("<p>I'm creating your account...</p>");
    this.appendOutput(
      `For your safety, I'll send your temporary password to your WhatsApp."
      `
    );
  }
}

customElements.define("ai-terminal", AiTerminal);
