class AiAssistants extends HTMLElement {
  connectedCallback() {
    this.render();
  }

  render() {
    this.innerHTML = `
      <style>
        ai-assistants {
          display: flex;
        }
        #assistants {
          display: flex;
          flex-direction: row;
          gap: 1em;
        }
      </style>
      <div id="assistants">
        <a href="/assistants/shopify">shopify.com</a>
        <a href="/assistants/youtube">youtube.com</a>
      </div>
    `;
  }
}

customElements.define("ai-assistants", AiAssistants);

module.exports = AiAssistants;
